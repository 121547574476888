<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title> File List </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :items="items.data"
        :server-items-length="items.total"
        :options.sync="pagination"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 30, 50, 100, -1],
        }"
        :loading="loading"
        item-key="file_id"
        class="elevation-1"
      >
        <template v-slot:item.file_name="{ item }">
          <span ref="textToCopy" :id="'textToCopy_' + item.file_id">{{
            item.file_name
          }}</span>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn
            color="grey darken-2"
            icon
            dark
            v-clipboard:copy="item.file_name"
            v-clipboard:success="onCopy"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
          <v-btn
            color="red"
            icon
            dark
            @click="
              dialog = true;
              file_id = item.file_id;
            "
          >
            <v-icon>mdi mdi-delete-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-text class="pt-5">
            Are you sure delete this file ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-4" text @click="dialog = false"
              >Cancel</v-btn
            >
            <v-btn color="red darken-4" text @click="deleteFile">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-btn color="primary" fixed fab bottom right to="/file/add">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { parseParams, getAlphabets } from "../../plugins/helper";
import { mapMutations } from "vuex";
export default {
  name: "Filelist",
  data() {
    return {
      loading: false,
      dialog: false,
      file_id: "",
      category: {},
      search: "",
      items: {
        current_page: 1,
        per_page: 50,
        data: [],
      },
      headers: [
        { text: "Title", value: "file_title" },
        { text: "File URL", value: "file_name" },
        { text: "Action", value: "action" },
      ],
      pagination: {
        itemsPerPage: 50,
        sortBy: ["created_at"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    search() {
      this.getItems();
    },
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    onCopy() {
      this.setAlert({
        show: true,
        variant: "default",
        message: "File URL copied",
        dismissCountDown: 1000,
      });
    },
    deleteFile() {
      this.loading = "secondary";
      let formData = new FormData();

      formData.append("file_id", this.file_id);

      this.$axios
        .post("/admin/file/delete", formData)
        .then(() => {
          this.loading = false;
          this.file_id = "";
          this.dialog = false;
          this.getItems();
        })
        .catch(function () {
          this.loading = false;
        });
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios
        .get("/admin/file/list?" + url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
  },
};
</script>
